<template>
  <div id="home-ourproducts">
    <section class="sc-main">
      <div class="sc-wrapper">
        <div class="inside-wrap" v-for="item in articulos" :key="item.id">
          <CardProductHomeComp :item="item" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CardProductHomeComp from "Components/global/cards/CardProductHomeComp.vue";
import Productos from "@/data/articles.json";

export default {
  data() {
    return {
      articulos: Productos.data,
    };
  },
  components: {
    CardProductHomeComp,
  },
};
</script>
<style scoped lang="scss">
#home-ourproducts {
  width: 100%;
  max-width: 2200px;
  margin: auto;

  .mb-15 {
    margin-bottom: 5rem;
  }

  .sc-main {
    width: 100%;
    padding: 0 0 5rem 0;

    @media (max-width: 767px) {
      padding: 0;
    }

    .sc-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      // overflow: visible;

      .inside-wrap {
        display: flex;
        // overflow: visible !important;
      }
    }
  }
  h3 {
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }
}
</style>
