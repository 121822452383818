<template>
  <div id="home-tulumsection">
    <div class="sc-main scroll-snap anim-wrapper">
      <div class="sc-left">
        <div class="scl-top">
          <div
            class="sclt-left"
            :class="[
              isMobile ? 'anim-up' : '',
              isTablet ? 'anim-up' : '',
              !isMobile && !isTablet ? 'anim-left' : '',
            ]"
            style="
              transition: all 1s ease;
              -webkit-transition-delay: 400ms;
              -moz-transition-delay: 400ms;
              -o-transition-delay: 400ms;
              transition-delay: 400ms;
            "
          >
            <div class="logo-cont">
              <img src="/static/logos/logo-image-black.webp" alt="" />
            </div>
          </div>
          <div
            class="sclt-right"
            :class="[
              isMobile ? 'anim-left' : '',
              isTablet ? 'anim-left' : '',
              !isMobile && !isTablet ? 'anim-up' : '',
            ]"
            style="
              transition: all 1s ease;
              -webkit-transition-delay: 200ms;
              -moz-transition-delay: 200ms;
              -o-transition-delay: 200ms;
              transition-delay: 200ms;
            "
          >
            <div class="scltr-txt-cont">
              <img src="/static/logos/tulum-logo-topbar.webp" alt="" />
              <!-- <h4 class="fsm-4">Descrubrí la comodidad al aire libre</h4> -->
              <p class="fsm-3 mb-3">
                Colección de mobiliario exclusivo apto intemperie by
                FabricTienda.
              </p>
              <a
                class="mos-btn"
                target="_blank"
                href="https://tulumoutdoor.com.ar/"
                >Visitá la web</a
              >
            </div>
          </div>
        </div>
        <div
          class="scl-bot"
          :class="[
            isMobile ? 'anim-right' : '',
            isTablet ? 'anim-right' : '',
            !isMobile && !isTablet ? 'anim-down' : '',
          ]"
          style="
            transition: all 1s ease;
            -webkit-transition-delay: 600ms;
            -moz-transition-delay: 600ms;
            -o-transition-delay: 600ms;
            transition-delay: 600ms;
          "
        >
          <div
            class="imge"
            style="
              background-image: url('/static/images/tulum/art-tulum1.webp');
            "
          ></div>
        </div>
      </div>
      <div class="sc-right">
        <div
          class="scr-top"
          :class="[
            isMobile ? 'anim-left' : '',
            isTablet ? 'anim-left' : '',
            !isMobile && !isTablet ? 'anim-right' : '',
          ]"
          style="
            transition: all 1s ease;
            -webkit-transition-delay: 1600ms;
            -moz-transition-delay: 1600ms;
            -o-transition-delay: 1600ms;
            transition-delay: 1600ms;
          "
        >
          <div class="scrt-txt">
            <h4 class="fsm-5">Linea Tulum Aluminio</h4>
            <a
              class="fsm-4"
              target="_blank"
              href="https://tulumoutdoor.com.ar/article/0000001_LineaAluminio"
              >Ver más<i class="fa-solid fa-arrow-up-right-from-square"></i
            ></a>
          </div>
        </div>
        <div
          class="scr-mid"
          :class="[
            isMobile ? 'anim-left' : '',
            isTablet ? 'anim-left' : '',
            !isMobile && !isTablet ? 'anim-right' : '',
          ]"
          style="
            transition: all 1s ease;
            -webkit-transition-delay: 1300ms;
            -moz-transition-delay: 1300ms;
            -o-transition-delay: 1300ms;
            transition-delay: 1300ms;
          "
        >
          <div
            class="imga"
            style="
              background-image: url('/static/images/tulum/art-tulum2.webp');
            "
          />
        </div>
        <div
          class="scr-bot"
          :class="[
            isMobile ? 'anim-right' : '',
            isTablet ? 'anim-right' : '',
            !isMobile && !isTablet ? 'anim-down' : '',
          ]"
          style="
            transition: all 1s ease;
            -webkit-transition-delay: 800ms;
            -moz-transition-delay: 800ms;
            -o-transition-delay: 800ms;
            transition-delay: 800ms;
          "
        >
          <div class="scrb-txt">
            <h4 class="fsm-5">Linea Tulum Vinílica</h4>
            <a
              class="fsm-4"
              target="_blank"
              href="https://tulumoutdoor.com.ar/article/0000002_LineaVinilica"
              >Ver más<i class="fa-solid fa-arrow-up-right-from-square"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mobileChecker from "@/mixins/mobileChecker";

export default {
  mixins: [mobileChecker],
};
</script>
<style scoped lang="scss">
#home-tulumsection {
  width: 100%;
  max-width: 2200px;
  margin: auto;
  max-height: 1500px;

  @media (max-width: 767px) {
    max-height: unset;
  }
  @media (min-width: 768px) {
    max-height: unset;
  }
  @media (min-width: 992px) {
    max-height: 1500px;
  }

  .sc-main {
    height: calc(100vh - var(--header-height));
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    @media (max-width: 767px) {
      flex-direction: column;
      height: unset;
    }
    @media (min-width: 768px) {
      height: unset;
      flex-direction: column;
    }
    @media (min-width: 992px) {
      height: calc(100vh - var(--header-height));
      flex-direction: row;
    }

    .sc-left {
      width: 55%;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media (max-width: 767px) {
        width: 100%;
      }
      @media (min-width: 768px) {
        width: 100%;
      }
      @media (min-width: 992px) {
        width: 55%;
      }

      .scl-top {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 52%;

        @media (max-width: 767px) {
          height: unset;
          position: relative;
          flex-direction: column;
        }
        @media (min-width: 768px) {
          height: 25rem;
          position: relative;
        }
        @media (min-width: 992px) {
          height: 52%;
        }

        .sclt-left {
          height: 100%;
          width: 50%;
          background-color: white;
          position: relative;
          display: flex;
          flex-direction: row;

          @media (max-width: 767px) {
            width: 100%;
            height: 14rem;
          }
          @media (min-width: 768px) {
          }
          @media (min-width: 992px) {
          }

          .logo-cont {
            width: 100%;
            display: grid;
            place-items: center;

            @media (max-width: 767px) {
              justify-content: center;
              padding: 1.5rem 5% 0.5rem 5%;
              height: 100%;
            }
            @media (min-width: 768px) {
            }
            @media (min-width: 992px) {
            }

            img {
              width: 100%;
              height: auto;
              max-width: 250px;

              @media (max-width: 767px) {
                height: 12rem;
                width: auto;
              }
            }
          }
        }

        .sclt-right {
          height: 100%;
          width: 65%;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 767px) {
            width: 100%;
            height: unset;
          }
          @media (min-width: 768px) {
            width: 70%;
          }
          @media (min-width: 992px) {
            width: 65%;
          }

          .scltr-txt-cont {
            width: 80%;
            height: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (max-width: 767px) {
              height: unset;
              width: 100%;
              padding: 1rem 5% 3rem 5%;
            }

            .mos-btn {
              padding: 0.5rem 3rem;
              background-color: #f77900;

              &:hover {
                background-color: #414344;
              }
            }

            & > * {
              text-align: left;

              @media (max-width: 767px) {
                text-align: center;
              }
            }

            img {
              width: 60%;
              height: auto;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 1rem;
              z-index: 9999;

              @media (max-width: 767px) {
                width: 60%;
                margin-bottom: 1.5rem;
              }
            }

            h4 {
              margin-bottom: 0.75rem;
              padding: 0 3%;
              text-align: center;
              width: 100%;
              text-transform: uppercase;

              @media (max-width: 767px) {
                padding: 0;
              }
            }

            p {
              padding: 0 3%;
              text-align: center;
            }
          }
        }
      }

      .scl-bot {
        height: 48%;
        width: 100%;
        position: relative;

        @media (max-width: 767px) {
          height: 22rem;
        }
        @media (min-width: 768px) {
          height: 27rem;
        }
        @media (min-width: 992px) {
          height: 48%;
        }

        .sc-connector {
          position: absolute;
          width: auto;
          height: 4rem;
          right: -2rem;
          bottom: 0;
          background-color: $color-black;
          z-index: 9999;
        }

        .imge {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: fixed;
          left: 0;
          top: 0;
        }
      }
    }

    .sc-right {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media (max-width: 767px) {
        width: 100%;
        flex-direction: column-reverse;
      }
      @media (min-width: 768px) {
        width: 100%;
        flex-direction: column-reverse;
      }
      @media (min-width: 992px) {
        width: 45%;
        flex-direction: column;
      }

      .scr-top {
        height: 25%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #c6924e;

        @media (max-width: 767px) {
          height: 10rem;
        }
        @media (min-width: 768px) {
          height: 12rem;
        }
        @media (min-width: 992px) {
          height: 25%;
        }

        .scrt-txt {
          width: 80%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;

          h4 {
            color: white;
          }

          a {
            text-decoration: none;
            color: $color-beige;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: white;
            }

            i {
              margin-left: 1rem;
              font-size: 1.2em;
            }
          }
        }
      }
      .scr-mid {
        height: 50%;
        width: 100%;

        @media (max-width: 767px) {
          height: 22rem;
        }
        @media (min-width: 768px) {
          height: 27rem;
        }
        @media (min-width: 992px) {
          height: 50%;
        }

        .imga {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .scr-bot {
        widows: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ed7d1d;
        position: relative;

        @media (max-width: 767px) {
          height: 10rem;
        }
        @media (min-width: 768px) {
          height: 12rem;
        }
        @media (min-width: 992px) {
          height: 25%;
        }

        img {
          z-index: 9999;
          position: absolute;
          left: -4rem;
          bottom: 50%;
          transform: translateY(50%);
          width: 8rem;
          height: auto;
        }

        .scrb-txt {
          width: 80%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          @media (max-width: 767px) {
            text-align: center;
          }
          @media (min-width: 768px) {
            text-align: center;
          }
          @media (min-width: 992px) {
            text-align: left;
          }

          h4 {
            color: white;
          }

          a {
            text-decoration: none;
            color: $color-beige;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: white;
            }

            i {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}
</style>
