<template>
  <div id="home-page">
    <HeroComp class="page_section scroll-snap" />
    <AboutUsComp class="page_section scroll-snap" />
    <FeaturesComp class="page_section" />
    <OurProductsComp class="page_section" />
    <TulumSection class="page_section" />
    <TestimonialsComp class="page_section" />
    <TendenciasComp class="page_section" />
    <Footer class="page_section" />
  </div>
</template>
<script>
import HeroComp from "./components/HeroComp.vue";
import AboutUsComp from "./components/AboutUsComp.vue";
import FeaturesComp from "./components/FeaturesComp.vue";
import OurProductsComp from "./components/OurProductsComp.vue";
import TulumSection from "./components/TulumSection.vue";
import TestimonialsComp from "./components/TestimonialsComp.vue";
import TendenciasComp from "./components/TendenciasComp.vue";
import Footer from "Components/footer/Footer.vue";
import useSlideAnimations from "@/composables/slideAnimations.js";

export default {
  beforeCreate() {
    document.title = "FabricTienda - Home";
  },
  mounted() {
    document.addEventListener("scroll", useSlideAnimations);

    if (this.$route.query.section) {
      this.scrollTo(this.$route.query.section);
    } else {
      window.scrollTo(0, 0);
    }
  },
  beforeUnmount() {
    document.removeEventListener("scroll", useSlideAnimations);
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  components: {
    HeroComp,
    AboutUsComp,
    FeaturesComp,
    OurProductsComp,
    TulumSection,
    TestimonialsComp,
    TendenciasComp,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
#home-page {
  position: relative;
}
</style>
