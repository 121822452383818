import { createStore } from "vuex";
import mailing from "./_mailing";
import ig_token from "./_ig_token";

export default createStore({
  modules: {
    mailing,
    ig_token,
  },
});
