<template>
  <div id="topbar-menu">
    <div id="topbar-menu-wrap" class="sc-main anim-topbar-wrapper">
      <section class="sc-left">
        <div class="scl-top anim-left">
          <div class="sclt-wrap">
            <div class="sclt-txt">
              <img
                class="img-cont"
                src="/static/images/menu/menu-img1.webp"
                alt=""
              />
              <!-- <div class="txt-cont">
                <div class="txt-1">Modelo Destacado</div>
                <div class="txt-2">HIT</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="scl-bot">
          <div class="sclb-left anim-left">
            <a class="wp-btn" target="_blank" :href="link_whatsapp">
              <i class="fa-brands fa-whatsapp"></i>
              <h4 class="fsm-5">Asesorate con un especialista</h4>
            </a>
          </div>
          <div class="sclb-right anim-down">
            <div class="sclbr-wrap">
              <div class="sclbr-txt">Menu</div>
            </div>
          </div>
        </div>
      </section>
      <section class="sc-right anim-down">
        <div class="scr-wrap anim-menu-wrapper">
          <div class="scr-txt">
            <a class="anim-link-up" @click="goLink('home', 'home-hero')"
              ><span class="fsm-7">Home</span></a
            >
            <a
              class="anim-link-up"
              style="transition-delay: 100ms"
              @click="goLink('home', 'home-aboutus')"
              ><span class="fsm-7">Sobre Nosotros</span></a
            >

            <a
              class="anim-link-up"
              style="transition-delay: 200ms"
              @click="goLink('home', 'home-ourproducts')"
              ><span class="fsm-7">Productos</span></a
            >

            <a
              class="anim-link-up"
              style="transition-delay: 300ms"
              @click="goLink('home', 'home-tulumsection')"
              ><span class="fsm-7">Tulum Outdoor</span></a
            >

            <!-- <a
              class="anim-link-up"
              style="transition-delay: 400ms"
              @click="goLink('home', 'home-testimonials')"
              ><span class="fsm-7">Testimonios</span></a
            > -->

            <a
              class="anim-link-up"
              style="transition-delay: 500ms"
              @click="goLink('home', 'home-tendencias')"
              ><span class="fsm-7">Instagram</span></a
            >

            <a
              class="anim-link-up"
              style="transition-delay: 600ms"
              @click="goLink('home', 'footer-main')"
              ><span class="fsm-7">Contacto</span></a
            >
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Menu from "@/data/menu.json";

export default {
  data() {
    return {
      menus: Menu.data,
      link_whatsapp: null,
    };
  },
  mounted() {
    document.body.classList.add("remove-overflow");
    setTimeout(() => this.menuToggle(), 100);
    this.link_whatsapp = this.$whatsapp;
  },
  watch: {
    isActive() {
      this.menuToggle();
    },
  },
  methods: {
    goLink(page, sec) {
      if (page == "home") {
        this.$router.push({ name: page, query: { section: sec } });
      } else {
        this.$router.push({ name: page, params: { nombre: sec } });
      }
      document
        .querySelector(".anim-topbar-wrapper")
        .classList.remove("is-shown");
      document.querySelector(".anim-menu-wrapper").classList.remove("is-shown");
      setTimeout(() => this.$emit("closeMenu"), 1200);
    },
    menuToggle() {
      if (this.isActive == true) {
        document
          .querySelector(".anim-topbar-wrapper")
          .classList.add("is-shown");
        setTimeout(
          () =>
            document
              .querySelector(".anim-menu-wrapper")
              .classList.add("is-shown"),
          500
        );
      } else {
        document
          .querySelector(".anim-topbar-wrapper")
          .classList.remove("is-shown");
        document
          .querySelector(".anim-menu-wrapper")
          .classList.remove("is-shown");
        setTimeout(() => this.$emit("closeMenu"), 1200);
      }
    },
  },
  props: {
    isActive: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
<style scoped lang="scss">
#topbar-menu {
}
</style>
