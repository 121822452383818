<template>
  <div id="home-tendencias" class="scroll-snap">
    <section class="sc-main">
      <div class="ig-data">
        <div class="data-img">
          <div class="gradient">
            <div class="image-cont">
              <div
                class="image"
                style="
                  background-image: url('/static/logos/fabrictienda-ig-ft.webp');
                "
              ></div>
            </div>
          </div>
        </div>
        <div class="data-txt">
          <h4>@fabrictienda</h4>
          <a
            class="fsm-2"
            target="_blank"
            :href="'https://www.instagram.com/' + link_instagram"
          >
            Ver más <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </a>
        </div>
      </div>
      <!-- <a :href="'https://www.instagram.com/' + $instagram" class="mos-btn">
        <i class="fa-brands fa-instagram"></i>fabrictienda
      </a> -->
      <div class="ig-feed" v-if="instagram_token">
        <InstagramFeed
          :count="!isMobile ? 6 : 3"
          :accessToken="instagram_token"
          :caption="true"
        />
      </div>
    </section>
  </div>
</template>
<script>
import mobileChecker from "@/mixins/mobileChecker";
import { mapGetters } from "vuex";

export default {
  mixins: [mobileChecker],
  data() {
    return {
      link_instagram: null,
      link_whatsapp: null,
    };
  },
  computed: {
    ...mapGetters(["instagram_token"]),
  },
  mounted() {
    this.link_instagram = this.$instagram;
    this.link_whatsapp = this.$whatsapp;

    this.$store.dispatch("getIgToken");
  },
};
</script>
<style scoped lang="scss">
#home-tendencias {
  width: 100%;
  max-width: 2200px;
  margin: auto;
  background-color: $color-quaternary;
  height: calc(100vh - var(--header-height));
  max-height: 1500px;

  @media (max-width: 767px) {
    height: unset;
    max-height: unset;
    min-height: 70rem;
  }
  @media (min-width: 768px) {
    height: unset;
  }
  @media (min-width: 992px) {
    height: calc(100vh - var(--header-height));
  }

  .sc-main {
    width: 100%;
    padding: 1.5rem 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767px) {
      padding: 2rem 7%;
    }
    @media (min-width: 768px) {
      padding: 1.5rem 10%;
    }
    @media (min-width: 992px) {
      height: 100%;
      padding: 1.5rem 20%;
    }
    @media (min-width: 1200px) {
      padding: 1rem 20%;
    }
    @media (min-width: 1460px) {
      padding: 1.5rem 20%;
    }

    .ig-data {
      display: flex;
      width: 100%;

      @media (max-width: 767px) {
        height: unset;
        padding-bottom: 2rem;
      }
      @media (min-width: 768px) {
        height: unset;
        padding-bottom: 1.5rem;
      }
      @media (min-width: 992px) {
        height: 20%;
        padding-bottom: 2rem;
      }
      @media (min-width: 1200px) {
      }
      @media (min-width: 1460px) {
        height: 15%;
      }

      .data-img {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
        }
        @media (min-width: 768px) {
        }
        @media (min-width: 992px) {
          height: 100%;
        }
        @media (min-width: 1200px) {
        }
        @media (min-width: 1460px) {
        }
        .gradient {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          background: linear-gradient(to right, red, #d300c5, orange);
          padding: 2px;

          @media (max-width: 767px) {
            width: 100px;
            height: 100px;
          }
          @media (min-width: 768px) {
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1200px) {
            width: 90px;
            height: 90px;
          }
          @media (min-width: 1460px) {
            width: 120px;
            height: 120px;
          }
        }

        .image-cont {
          width: 100%;
          height: 100%;
          background-color: $color-quaternary;
          padding: 3px;
          border-radius: 50%;
        }

        .image {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      .data-txt {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        align-items: flex-start;
        justify-content: center;

        @media (max-width: 767px) {
          padding-left: 1rem;
        }
        @media (min-width: 768px) {
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1200px) {
          padding-left: 1rem;
        }
        @media (min-width: 1460px) {
          padding-left: 2rem;
        }

        h4 {
          font-weight: 400;
        }
        a {
          text-decoration: none;
          color: black;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: black;
          }

          i {
            margin-left: 1rem;
            font-size: 1.2em;

            @media (max-width: 767px) {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }

    .mos-btn {
      i {
        font-size: 22px;
        margin-right: 0.5rem;
      }

      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        padding: 0.75rem;
      }
    }

    .ig-feed {
      width: 100%;
      @media (max-width: 767px) {
        height: unset;
      }
      @media (min-width: 768px) {
        height: unset;
      }
      @media (min-width: 992px) {
        height: 80%;
      }
      @media (min-width: 1200px) {
      }
      @media (min-width: 1460px) {
        height: 85%;
      }
    }
  }
}
</style>
