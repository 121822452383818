<template>
  <div id="article-gallery-comp">
    <div class="sc-main" v-if="items">
      <swiper
        :style="{
          '--swiper-navigation-color': '#eb8d2d',
          '--swiper-pagination-color': '#eb8d2d',
        }"
        :spaceBetween="10"
        :navigation="!isMobile ? true : false"
        :pagination="{
          dynamicBullets: true,
          dynamicMainBullets: 5,
        }"
        :thumbs="{ swiper: thumbsSwiper }"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="item in items" :key="item.id">
          <img :src="item.path" />
        </swiper-slide>
      </swiper>
      <swiper
        @swiper="setThumbsSwiper"
        :spaceBetween="10"
        :slidesPerView="4"
        :freeMode="true"
        :watchSlidesProgress="true"
        :modules="modules"
        class="mySwiperPreview"
        v-if="!isMobile"
      >
        <swiper-slide v-for="item in items" :key="item.id">
          <div
            class="slide-img"
            :style="{ 'background-image': 'url(' + item.path + ')' }"
          ></div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import mobileChecker from "@/mixins/mobileChecker";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";

export default {
  mixins: [mobileChecker],
  props: ["items"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbsSwiper: null,
    };
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
  setup() {
    return {
      modules: [FreeMode, Pagination, Navigation, Thumbs],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "swiper/css/pagination";

#article-gallery-comp {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 2rem;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: 50%;
  }

  .sc-main {
    width: 100%;
  }

  .mySwiper {
    .swiper-button-next,
    .swiper-button-prev {
      color: $color-primary;
    }
    .swiper-slide {
      height: 450px;
      position: relative;

      @media (max-width: 767px) {
        height: 250px;
      }

      img {
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        -o-object-fit: contain;
        object-fit: contain;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        height: 150px;
      }
    }
    .swiper-pagination-bullet-active {
      background-color: #000 !important;
    }
  }

  .mySwiperPreview {
    width: 100%;
    margin-top: 1rem;

    .swiper-wrapper {
      flex-direction: column;
    }
    .swiper-slide {
      aspect-ratio: 1 / 1;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &.swiper-slide-thumb-active {
        background-color: $color-primary;

        .slide-img {
          opacity: 0.7;
        }
      }

      .slide-img {
        height: 95%;
        width: 95%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
