<template>
  <div id="home-hero" class="scroll-snap">
    <div class="sc-main anim-wrapper fifth-anim">
      <div class="hero-text fourt-anim anim-wrapper">
        <img
          class="hero-draw anim-hero-draw"
          src="/static/logos/fabrictienda-logo-min.webp"
        />
        <div class="t-m">
          <div class="first-anim anim-wrapper">
            <h1 class="anim-opacity fsm-8">
              Fabricamos mobiliario
              <br />
              <div style="width: 100%">
                <span class="typed-text">{{ typeValue }}</span>
                <span class="blinking-cursor">|</span>
                <span class="cursor" :class="{ typing: typeStatus }"
                  >&nbsp;</span
                >
              </div>
              para tus espacios
            </h1>
          </div>
          <div class="second-anim anim-wrapper">
            <p class="subtitle anim-opacity fsm-4">
              Todo personalizado y único como vos
            </p>
          </div>
          <div class="third-anim anim-wrapper">
            <div class="anim-opacity">
              <div
                class="mos-btn-outlined"
                @click="scrollTo('home-ourproducts')"
              >
                Ver Productos
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="hero-img"
        :class="isMobile ? 'anim-down' : 'anim-right'"
        style="transition: all 1.2s ease"
      >
        <div class="hero-img-overlay"></div>
        <swiper
          v-if="GalleryItems"
          style="height: 100%; width: 100%"
          :spaceBetween="0"
          :navigation="false"
          :pagination="false"
          :effect="'fade'"
          :autoplay="{
            delay: 4500,
            disableOnInteraction: false,
          }"
          :modules="modules"
          class="mySwiper"
        >
          <swiper-slide
            v-for="item in GalleryItems"
            :key="item.id"
            :style="{ 'background-image': 'url(' + item.path + ')' }"
            style="
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            "
          >
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import Imagenes from "@/data/galleries.json";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, EffectFade } from "swiper";
import mobileChecker from "@/mixins/mobileChecker";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

export default {
  mixins: [mobileChecker],
  data() {
    return {
      heroimages: Imagenes.data,
      itemsIds: [56, 41, 2, 78, 105, 122, 129, 58, 31, 9, 74, 86, 107, 135],
      typeValue: "",
      typeStatus: false,
      displayTextArray: ["de calidad", "premium", "de estilo", "especial"],
      typingSpeed: 100,
      erasingSpeed: 100,
      newTextDelay: 2000,
      displayTextArrayIndex: 0,
      charIndex: 0,
    };
  },
  setup() {
    return {
      modules: [Autoplay, EffectFade],
    };
  },
  computed: {
    GalleryItems: function () {
      return this.heroimages.filter((e) => this.itemsIds.includes(e.id));
    },
  },
  mounted() {
    setTimeout(() => {
      document.querySelector(".first-anim").classList.add("is-shown");
    }, 600);
    setTimeout(() => {
      document.querySelector(".second-anim").classList.add("is-shown");
    }, 1500);
    setTimeout(() => {
      document.querySelector(".third-anim").classList.add("is-shown");
    }, 2100);
    setTimeout(() => {
      document.querySelector(".fourt-anim").classList.add("is-shown");
    }, 2100);
    setTimeout(() => {
      document.querySelector(".fifth-anim").classList.add("is-shown");
    }, 2600);
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 1000);
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    typeText() {
      if (
        this.charIndex <
        this.displayTextArray[this.displayTextArrayIndex].length
      ) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.displayTextArray[
          this.displayTextArrayIndex
        ].charAt(this.charIndex);
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.displayTextArray[
          this.displayTextArrayIndex
        ].substring(0, this.charIndex - 1);
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.displayTextArrayIndex += 1;
        if (this.displayTextArrayIndex >= this.displayTextArray.length)
          this.displayTextArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
  },
};
</script>
<style scoped lang="scss">
#home-hero {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: $color-beige;
  max-height: 1500px;
  max-width: 2200px;
  margin: auto;
  @media (max-width: 767px) {
    height: 100vh;
  }
  @media (max-width: 767px) {
    height: unset;
  }
  @media (min-width: 768px) {
    height: unset;
  }
  @media (min-width: 992px) {
    height: 100vh;
  }

  .sc-main {
    height: 100%;
    width: 100%;
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
      height: unset;
    }
    @media (min-width: 768px) {
      flex-direction: column;
      height: unset;
    }
    @media (min-width: 992px) {
      height: 100%;
      flex-direction: row;
    }
  }

  .hero-text {
    width: 55%;
    max-width: 1800px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 75px 2% 0 10%;
    position: relative;
    overflow: visible;

    .blinking-cursor {
      font-weight: 400;
      color: $color-secondary;
    }

    @media (max-width: 767px) {
      width: 100%;
      height: unset;
      padding: 15vh 5% 3rem 5%;
    }
    @media (min-width: 768px) {
      width: 100%;
      height: unset;
      padding: 15vh 5% 3rem 5%;
    }
    @media (min-width: 992px) {
      width: 55%;
      height: 100%;
      padding: var(--header-height) 2% 0 5%;
    }
    @media (min-width: 1200px) {
      width: 55%;
      height: 100%;
      padding: var(--header-height) 2% 0 10%;
    }

    .hero-draw {
      position: absolute;
      bottom: -400px;
      right: -750px;
      width: 200%;
      height: auto;

      @media (max-width: 767px) {
        bottom: -50%;
        right: -110%;
      }
      @media (min-width: 768px) {
        bottom: -100%;
        right: -85%;
        width: 170%;
      }
      @media (min-width: 992px) {
        bottom: -40%;
        right: -125%;
        width: 230%;
      }
      @media (min-width: 1200px) {
        bottom: -45%;
        right: -120%;
        width: 220%;
      }
      @media (min-width: 1460px) {
        bottom: -50%;
        right: -100%;
        width: 200%;
      }
    }

    .t-m {
      position: relative;
      z-index: 999;
      overflow: unset;

      h1 {
        font-weight: 800;
        margin-bottom: 1.5rem;
        color: $color-black;

        @media (max-width: 767px) {
          font-weight: 600;
          letter-spacing: -0.1rem;
          margin-bottom: 1rem;
        }
        @media (min-width: 768px) {
          font-weight: 800;
        }
      }
      .subtitle {
        font-weight: 400;
        margin-bottom: 1.5rem;
        color: $color-black;

        @media (max-width: 767px) {
          font-weight: 400;
          margin-bottom: 1.5rem;
        }
      }
      .mos-btn {
        z-index: 999;
        @media (max-width: 767px) {
          width: 100%;
          justify-content: center;
          padding: 0.75rem;
        }
      }
    }
  }

  .hero-img {
    width: 45%;
    height: 100%;
    position: relative;

    @media (max-width: 767px) {
      height: calc(26rem + 3vh);
      width: 100%;
    }
    @media (min-width: 768px) {
      height: calc(32rem + 3vh);
      width: 100%;
    }
    @media (min-width: 992px) {
      height: 100%;
      width: 45%;
    }

    .hero-img-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba($color: $color-white, $alpha: 0.15);
      z-index: 999;
      pointer-events: none;
    }
  }
}
</style>
