<template>
  <div id="features-comp" class="scroll-snap">
    <div class="sc-main scroll-snap anim-wrapper">
      <section
        class="sc-top anim-down"
        style="
          background-image: url('/static/images/aboutus/aboutus-img3.webp');
        "
      >
        <div class="sct-cont">
          <div class="sct-wrap">
            <img src="/static/logos/fabrictienda-logo-min-byw.webp" alt="" />
          </div>
        </div>
      </section>
      <section class="sc-bot">
        <div class="scb-cont">
          <div class="scb-wrap">
            <div
              class="card-cont anim-left"
              :style="
                isMobile ? 'transition-delay: 200ms' : 'transition-delay: 300ms'
              "
            >
              <div class="feature-card">
                <div class="fc-body">
                  <div class="fc-head">
                    <div class="icon-cont">
                      <i class="fa-solid fa-truck-fast"></i>
                    </div>
                  </div>
                  <div class="body-txt">
                    <h4 class="fsm-4">Envíos a toda la Argentina</h4>
                    <p class="fsm-3">
                      Servicio propio de instalación en CABA/GBA. Para envíos al
                      interior del país nos ocupamos de la logística buscando la
                      mejor opción mediante expresos de confianza
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-cont"
              :class="isMobile ? 'anim-left' : 'anim-right'"
              :style="
                isMobile ? 'transition-delay: 400ms' : 'transition-delay: 300ms'
              "
            >
              <div class="feature-card">
                <div class="fc-body">
                  <div class="fc-head">
                    <div class="icon-cont">
                      <i class="fa-solid fa-pen-to-square"></i>
                    </div>
                  </div>
                  <div class="body-txt">
                    <h4 class="fsm-4">A tu medida</h4>
                    <p class="fsm-3">
                      Nos adaptamos a tus necesidades. Podés personalizar cada
                      una de las dimensiones de tu sofá, también el género e
                      incluso su relleno. Te ofrecemos garantía de fábrica
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-cont anim-left"
              :style="
                isMobile ? 'transition-delay: 600ms' : 'transition-delay: 600ms'
              "
            >
              <div class="feature-card">
                <div class="fc-body">
                  <div class="fc-head">
                    <div class="icon-cont">
                      <i class="fa-solid fa-users-between-lines"></i>
                    </div>
                  </div>
                  <div class="body-txt">
                    <h4 class="fsm-4">Nuestro trayecto</h4>
                    <p class="fsm-3">
                      Más de 5.000 clientes con expectativas superadas. Nos
                      enfocamos en los detalles y te brindamos productos de
                      primera línea al mejor precio
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-cont"
              :class="isMobile ? 'anim-left' : 'anim-right'"
              :style="
                isMobile ? 'transition-delay: 800ms' : 'transition-delay: 600ms'
              "
            >
              <div class="feature-card">
                <div class="fc-body">
                  <div class="fc-head">
                    <div class="icon-cont">
                      <i class="fa-solid fa-headset"></i>
                    </div>
                  </div>
                  <div class="body-txt">
                    <h4 class="fsm-4">Espacios únicos</h4>
                    <p class="fsm-3">
                      Tenemos un grupo especialista y minucioso que te asesorará
                      para poder aprovechar al máximo tu espacio. Logrando así
                      una decoración armoniosa, seductora y altamente placentera
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import mobileChecker from "@/mixins/mobileChecker";

export default {
  mixins: [mobileChecker],
};
</script>
<style scoped lang="scss">
#features-comp {
  height: calc(100vh - var(--header-height));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 2200px;
  margin: auto;
  max-height: 1500px;

  @media (max-width: 767px) {
    height: unset;
  }
  @media (min-width: 768px) {
    height: unset;
  }
  @media (min-width: 992px) {
    height: calc(100vh - var(--header-height));
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1460px) {
  }
}
</style>
