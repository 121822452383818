<template>
  <nav id="topbar-tst">
    <div class="sc-main">
      <div class="sc-left">
        <ul>
          <li>
            <a @click="goHome()">
              <img
                class="logo-min"
                src="/static/logos/fabrictienda-logo-min.webp"
                alt=""
              />
            </a>
          </li>
          <li>
            <a @click="goHome()">
              <img
                class="logo-text"
                src="/static/logos/fabrictienda-logo-text.webp"
                alt=""
              />
            </a>
          </li>
        </ul>
      </div>
      <div class="sc-right">
        <SocialMediaLinksComp
          v-if="!isMobile"
          :class="isMenuActive ? 'links-open' : ''"
        />
        <ul class="menu-btn-cont">
          <li>
            <div
              class="menu-btn"
              :class="isMenuActive ? 'active' : ''"
              @click="menuToggle()"
            >
              <span></span><span></span><span></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <TopbarMenu
      v-if="showMenu"
      :isActive="isMenuActive"
      @closeMenu="closeMenu"
    />
  </nav>
</template>
<script>
import TopbarMenu from "Components/topbar/TopbarMenu.vue";
import SocialMediaLinksComp from "Components/global/SocialMediaLinksComp";
import mobileChecker from "@/mixins/mobileChecker";

export default {
  mixins: [mobileChecker],

  data() {
    return {
      showMenu: false,
      isMenuActive: false,
    };
  },
  methods: {
    menuToggle() {
      if (this.showMenu == true) {
        this.isMenuActive = false;
      } else {
        this.showMenu = true;
        this.isMenuActive = true;
      }
    },
    closeMenu() {
      this.isMenuActive = false;
      this.showMenu = false;
    },
    goHome() {
      if (this.$router.currentRoute.value.name == "home") {
        document.getElementById("app").scrollTo(0, 0);
      } else {
        this.$router.push({ name: "home" });
      }
    },
  },
  components: {
    TopbarMenu,
    SocialMediaLinksComp,
  },
};
</script>
<style scoped lang="scss">
#topbar-tst {
  width: 100%;
  height: var(--header-height);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  background-color: $color-beige;

  .sc-main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5%;
    position: relative;

    .sc-left {
      display: flex;
      width: fit-content;
      justify-content: flex-start;
      height: 100%;

      ul {
        height: 100%;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        padding-right: 1rem;
        padding-left: 0;
        margin-bottom: 0;

        li {
          display: flex;
          align-items: center;

          a {
            cursor: pointer;

            img {
              &.logo-min {
                height: 40px;
                width: auto;
                margin-right: 0.5rem;
                transition: opacity 0.3s ease;

                &:hover {
                  opacity: 0.8;
                }

                @media (max-width: 767px) {
                  height: 35px;
                  margin-right: 0.25rem;
                }
              }
              &.logo-text {
                height: 25px;
                width: auto;

                @media (max-width: 767px) {
                  height: 22px;
                }
              }
            }
          }
        }
      }
    }
    .sc-right {
      display: flex;
      width: fit-content;
      justify-content: flex-end;
      height: 100%;

      ul {
        height: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: stretch;

        &.menu-btn-cont {
          margin-left: 2rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .menu-btn {
            cursor: pointer;
          }

          .menu-btn > span {
            display: block;
            width: 28px;
            height: 2px;
            border-radius: 9999px;
            background-color: black;

            @media (max-width: 767px) {
              width: 25px;
            }
          }

          .menu-btn > span:not(:last-child) {
            margin-bottom: 7px;
          }

          .menu-btn,
          .menu-btn > span {
            transition: all 0.4s ease-in-out;
          }

          .menu-btn.active {
            transition-delay: 0.8s;
            transform: rotate(45deg);
          }

          .menu-btn.active > span:nth-child(2) {
            width: 0;
          }

          .menu-btn.active > span:nth-child(1),
          .menu-btn.active > span:nth-child(3) {
            transition-delay: 0.4s;
          }
          .menu-btn.active > span:nth-child(1) {
            transform: translateY(9px);
          }
          .menu-btn.active > span:nth-child(3) {
            transform: translateY(-9px) rotate(90deg);
          }
        }
      }
    }
  }
}
</style>
