<template>
  <div id="article-page">
    <div class="sc-main scroll-snap">
      <div class="article-wrapper">
        <ArticleGalleryComp v-if="GalleryItem" :items="GalleryItem" />
        <ArticleDataComp v-if="ArticleItem" :item="ArticleItem" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import ArticleDataComp from "./components/ArticleDataComp.vue";
import ArticleGalleryComp from "./components/ArticleGalleryComp.vue";
import Productos from "@/data/articles.json";
import Imagenes from "@/data/galleries.json";
import Footer from "Components/footer/Footer.vue";

export default {
  data() {
    return {
      articulos: Productos.data,
      galleries: Imagenes.data,
      product_id: null,
    };
  },
  components: {
    ArticleDataComp,
    ArticleGalleryComp,
    Footer,
  },
  beforeMount() {
    this.product_id = parseInt(this.$route.params.nombre.split("_")[0], 10);
    document.title = `FabricTienda - ${this.ArticleItem.name}`;
  },
  mounted() {
    document.getElementById("app").scrollTo({ top: 0, behavior: "smooth" });
  },
  computed: {
    ArticleItem: function () {
      return this.articulos.find((x) => x.id === this.product_id);
    },
    GalleryItem: function () {
      return this.galleries.filter((x) => x.product_id === this.product_id);
    },
  },
};
</script>
<style scoped lang="scss">
#article-page {
  margin-top: var(--header-height);
  width: 100%;

  .sc-main {
    width: 100%;
    height: calc(100vh - var(--header-height));
    display: flex;
    padding: 3rem 10% 3rem 10%;
    align-items: center;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      padding: 0;
      height: unset;
      min-height: calc(100vh - var(--header-height));
    }
    @media (min-width: 768px) {
      padding: 3rem 5% 3rem 5%;
      height: unset;
      min-height: calc(100vh - var(--header-height));
    }
    @media (min-width: 992px) {
      width: 100%;
      height: calc(100vh - var(--header-height));
      display: flex;
      padding: 3rem 10% 3rem 10%;
      align-items: center;
    }

    .article-wrapper {
      display: flex;
      width: 100%;

      @media (max-width: 767px) {
        flex-direction: column;
        padding: 2rem 0;
      }
      @media (min-width: 768px) {
        flex-direction: column;
      }
      @media (min-width: 992px) {
        flex-direction: row;
      }
    }
  }
}
</style>
