<template>
  <div id="footer-main" class="scroll-snap">
    <div class="main-container">
      <div class="sc-top">
        <section class="sc-left">
          <div class="scl-contact">
            <h4 class="fsm-5">CONTACTANOS</h4>
            <div class="scl-mail">
              <form @submit.prevent="handleSubmit">
                <div class="input-cont">
                  <input
                    v-model="form.email"
                    type="text"
                    placeholder="Dejanos tu email"
                    class="fsm-3"
                  />
                </div>
                <div class="input-cont">
                  <input
                    v-model="form.message"
                    type="text"
                    placeholder="Consulta lo que quieras"
                    class="fsm-3"
                  />
                </div>
                <button class="mos-btn-square" type="submit">
                  <i class="fa-regular fa-paper-plane"></i>Enviar
                </button>
              </form>
            </div>
            <div class="scl-phone">
              <p class="fsm-3">
                O hablanos al whatsapp para pedirnos <br />
                más información a cerca de nuestros productos
              </p>
              <a class="mos-btn-square" target="_blank" :href="link_whatsapp">
                <i class="fa-brands fa-whatsapp"></i>Asesorate
              </a>
            </div>
          </div>
        </section>
        <section class="sc-right">
          <div class="row">
            <div class="col-6">
              <div class="scr-menu">
                <h4 class="fsm-5">Modelos</h4>
                <ul>
                  <li v-for="item in articulos" :key="item.id">
                    <router-link :to="item.url" class="fsm-3 txt-link">{{
                      item.name.substring(7)
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-6">
              <div class="scr-menu">
                <h4 class="fsm-5">Información</h4>
                <ul>
                  <li v-for="item in menus" :key="item.id">
                    <router-link
                      :to="{ name: 'home', query: { section: item.url } }"
                      class="fsm-3 txt-link"
                      >{{ item.name }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="scr-menu menu-redes">
                <h4 class="fsm-5">Redes Sociales</h4>
                <ul>
                  <li>
                    <a
                      class="fsm-3"
                      target="_blank"
                      :href="'https://www.instagram.com/' + link_instagram"
                      ><i class="fa-brands fa-instagram"></i
                      ><span class="txt-link">instagram</span></a
                    >
                  </li>
                  <li>
                    <a class="fsm-3" target="_blank" :href="link_whatsapp"
                      ><i class="fa-brands fa-whatsapp"></i
                      ><span class="txt-link">whatsapp</span></a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-6">
              <div class="scr-menu">
                <h4 class="fsm-5">Medios de pago</h4>
                <ul>
                  <li>
                    <div class="li-cont">
                      <i class="fa-solid fa-money-bill"></i
                      ><span class="fsm-3">Efectivo</span>
                    </div>
                  </li>
                  <li>
                    <div class="li-cont">
                      <i class="fa-solid fa-wallet"></i>
                      <span class="fsm-3"
                        >Deposito o Transferencia Bancaria</span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="li-cont">
                      <i class="fa-solid fa-truck-fast"></i>
                      <span class="fsm-3">Envíos a todo el país</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="sc-bot">
        <div class="sc-logo">
          <img
            class="img-logo-min"
            src="/static/logos/fabrictienda-logo-min.webp"
            alt=""
          />
          <img
            class="img-logo-ft"
            src="/static/logos/fabrictienda-logo-text.webp"
            alt=""
          />
        </div>
        <div class="sc-cpr">
          <p class="fsm-2">© 2022 Fabrictienda. All Rights Reserved.</p>
        </div>
      </div>
    </div>
    <div class="recaptcha-wrap" v-if="showCaptcha">
      <vue-recaptcha
        class="recaptcha"
        ref="recaptcha"
        :sitekey="recaptcha_sitekey"
        @init="this.$refs.recaptcha.execute()"
        @verify="sendForm()"
      ></vue-recaptcha>
    </div>
  </div>
</template>
<script>
import Productos from "@/data/articles.json";
import Menu from "@/data/menu.json";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  data() {
    return {
      articulos: Productos.data,
      menus: Menu.data,
      link_instagram: null,
      link_whatsapp: null,
      form: {
        email: null,
        message: null,
      },
      showCaptcha: false,
      recaptcha_sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
    };
  },
  components: {
    VueRecaptcha,
  },
  mounted() {
    this.link_instagram = this.$instagram;
    this.link_whatsapp = this.$whatsapp;
  },
  methods: {
    handleSubmit() {
      if (!this.form.email) {
        alert("Por favor ingrese un correo electrónico válido");
        return;
      } else {
        this.verifyCaptcha();
      }
    },
    verifyCaptcha() {
      console.log("cha-verify");
      this.showCaptcha = true;
    },
    sendForm() {
      this.showCaptcha = false;
      console.log("cha-send");
      this.$store.dispatch("sendMail", this.form);
    },
  },
};
</script>
<style scoped lang="scss">
#footer-main {
  width: 100%;
  height: calc(100vh - var(--header-height));
  max-height: 1500px;
  max-width: 2200px;
  margin: auto;
  display: flex;
  background-color: $color-beige;

  @media (max-width: 767px) {
    height: unset;
  }
  @media (min-width: 768px) {
    height: unset;
  }
  @media (min-width: 992px) {
    height: calc(100vh - var(--header-height));
  }

  .txt-link {
    cursor: pointer;
    text-decoration: none;
    color: $color-black;
    position: relative;
    transition: all 0.2s ease-in-out;
    transition-delay: 200ms;
    transition-property: color;

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $color-secondary;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    &:hover:before {
      visibility: visible;
      width: 100%;
    }

    &:hover {
      color: $color-secondary;
    }
  }

  .main-container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 3rem 4% 3rem 4%;

    @media (max-width: 767px) {
      padding: 3rem 4% 0.5rem 4%;
    }
    @media (min-width: 768px) {
      padding: 3rem 4% 0.5rem 4%;
    }
    @media (min-width: 992px) {
      padding: 3rem 4% 0.5rem 4%;
    }
    @media (min-width: 1200px) {
      padding: 2rem 4% 0.5rem 4%;
    }
    @media (min-width: 1460px) {
      padding: 3rem 4% 2rem 4%;
    }

    .sc-top {
      width: 100%;
      height: 75%;
      display: flex;
      flex-direction: row;

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .sc-left {
      display: flex;
      width: 50%;
      height: 100%;

      @media (max-width: 767px) {
        width: 100%;
      }

      .scl-contact {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0 1rem;

        @media (max-width: 767px) {
          margin-bottom: 2rem;
          padding: 0;
        }

        .mos-btn-square {
          padding: 0.5rem 2rem;
          display: flex;
          align-items: center;
          z-index: 888;

          &:hover {
            background-color: $color-secondary;
            border-color: $color-secondary;
          }

          i {
            margin-right: 0.5rem;
            font-size: 1.3em;
          }
        }

        h4 {
          text-transform: uppercase;
          margin-bottom: 2rem;

          @media (max-width: 767px) {
            margin-bottom: 0.7rem;
          }
          @media (min-width: 768px) {
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1200px) {
            margin-bottom: 1rem;
          }
          @media (min-width: 1460px) {
            margin-bottom: 2rem;
          }
        }

        .scl-mail {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 1.5rem;

          form {
            width: 80%;
            .input-cont {
              padding: 0.6rem 0;
              border-bottom: 1px solid black;
              margin-bottom: 1.5rem;

              @media (max-width: 767px) {
                margin-bottom: 0.7rem;
                padding: 0.4rem 0;
              }

              input {
                background: transparent;
                border: none;

                &::placeholder {
                  color: black;
                  opacity: 0.7;
                }

                &:focus-visible {
                  border: none;
                  outline: none;
                }
              }
            }

            .mos-btn-square {
              @media (max-width: 767px) {
                margin-top: 1.5rem;
              }
            }
          }
        }

        .scl-phone {
          width: 100%;
          display: flex;
          flex-direction: column;

          p {
            margin-bottom: 1.5rem;
          }
        }
      }
    }

    .sc-right {
      display: flex;
      width: 50%;
      height: 100%;
      flex-direction: column;

      @media (max-width: 767px) {
        width: 100%;
      }

      .scr-menu {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 5%;
        margin-bottom: 2.5rem;

        @media (max-width: 767px) {
          padding: 0;
          margin-bottom: 1.5rem;
        }
        @media (min-width: 1200px) {
          margin-bottom: 1rem;
        }
        @media (min-width: 1460px) {
          margin-bottom: 1.5rem;
        }

        h4 {
          @media (max-width: 767px) {
            font-size: 1.1rem;
          }
        }

        &.menu-redes {
          li {
            a {
              i {
                font-size: 1.2em;
                margin-right: 0.5rem;
              }
            }
          }
        }

        h4 {
          margin-bottom: 1.5rem;
          text-transform: uppercase;

          @media (max-width: 767px) {
            margin-bottom: 0.7rem;
          }
          @media (min-width: 1200px) {
            margin-bottom: 0.5rem;
          }
          @media (min-width: 1460px) {
            margin-bottom: 1.5rem;
          }
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            margin: 0 0 1rem 0;

            @media (max-width: 767px) {
              margin: 0 0 0.7rem 0;
            }
            @media (min-width: 1200px) {
              margin: 0 0 0.5rem 0;
            }
            @media (min-width: 1460px) {
              margin: 0 0 1rem 0;
            }

            .li-cont {
              display: flex;
              color: black;
              i {
                margin-right: 0.5rem;
                font-size: 1.2em;
              }
            }

            a {
              text-transform: capitalize;
              cursor: pointer;
            }
          }
        }
      }

      .scr-left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .scr-right {
        width: 50%;
        height: 100%;
      }
    }

    .sc-bot {
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 767px) {
        height: unset;
        align-items: center;
      }

      .sc-logo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 2rem;
        height: 100%;

        @media (max-width: 767px) {
          margin-bottom: 0.5rem;
          justify-content: center;
        }
        @media (min-width: 768px) {
          margin-bottom: 0.5rem;
        }
        @media (min-width: 992px) {
          margin-bottom: 1rem;
        }
        @media (min-width: 1200px) {
          margin-bottom: 0.5rem;
        }
        @media (min-width: 1460px) {
          margin-bottom: 1rem;
        }

        .img-logo-min {
          height: 4rem;
          width: auto;

          @media (max-width: 767px) {
            height: 4rem;
          }
          @media (min-width: 768px) {
          }
          @media (min-width: 992px) {
            height: 3rem;
          }
          @media (min-width: 1200px) {
            height: 3rem;
          }
          @media (min-width: 1460px) {
            height: 4rem;
          }
        }

        .img-logo-ft {
          height: 2rem;
          width: auto;

          @media (max-width: 767px) {
            height: 2rem;
          }
          @media (min-width: 768px) {
            height: 2rem;
          }
          @media (min-width: 992px) {
            height: 1.5rem;
          }
          @media (min-width: 1200px) {
            height: 2rem;
          }
          @media (min-width: 1460px) {
            height: 2.5rem;
          }
        }
      }

      .sc-cpr {
        border-top: 1px solid black;
        padding: 0.25rem 25% 0.25rem 1.25rem;

        @media (max-width: 767px) {
          padding: 0.4rem 5%;
        }

        p {
          text-align: center;
        }
      }
    }
  }
}
</style>
