<template>
  <ul id="social-media-links">
    <li>
      <a target="_blank" :href="'https://www.instagram.com/' + link_instagram">
        <i class="fa-brands fa-instagram"></i>
        <div class="social-letters">instagram</div>
      </a>
    </li>
    <li>
      <a :href="link_whatsapp" target="_blank">
        <i class="fa-brands fa-whatsapp"></i>
        <div class="social-letters">whatsapp</div>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      link_instagram: null,
      link_whatsapp: null,
    };
  },
  mounted() {
    this.link_instagram = this.$instagram;
    this.link_whatsapp = this.$whatsapp;
  },
};
</script>
<style scoped lang="scss">
#social-media-links {
  list-style: none;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  margin: 0;
  padding: 0;

  &.links-open {
    li {
      @media (min-width: 768px) {
        i {
          opacity: 1;
        }
        .social-letters {
          width: 80px;
          margin-left: 5px;
          opacity: 1;
        }
      }
    }
  }

  li {
    cursor: pointer;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }

    @media (max-width: 767px) {
      margin-left: 25px;
    }
    @media (min-width: 768px) {
      &:hover {
        i {
          opacity: 1;
        }
        .social-letters {
          width: 80px;
          margin-left: 5px;
          opacity: 1;
        }
      }
    }

    i {
      font-size: 23px !important;
      color: $color-black;
      opacity: 1;
      transition: opacity ease 0.4s;

      @media (max-width: 767px) {
        font-size: 20px !important;
        opacity: 1;
      }
    }

    .social-letters {
      width: 0;
      overflow: hidden;
      transition: all 0.4s ease 0s;
      display: inline-flex;
      font-size: 15px;
      color: $color-black;
      opacity: 0.7;
    }
  }
}
</style>
