export default function useSlideAnimations(e, reset = false) {
  setTimeout(
    () => {
      const scrollAnimWrappers = document.querySelectorAll(
        ".anim-wrapper, .anim-prod-card-wrapper"
      );

      scrollAnimWrappers.forEach((x) => {
        if (reset && x.classList.contains("is-shown")) {
          x.classList.remove("is-shown");
        }

        setTimeout(
          () => {
            if (
              x.getBoundingClientRect().top < 600 &&
              !x.classList.contains("is-shown")
            ) {
              x.classList.add("is-shown");
            }
          },
          reset ? 250 : 200
        );
      });
    },
    reset ? 250 : 200
  );
}
