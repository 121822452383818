import axios from "axios";
const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: {},
  mutations: {},
  actions: {
    sendMail(_, form) {
      return new Promise((resolve) => {
        axios
          .post(`${baseUrl}mailing`, form)
          .then(({ data }) => {
            this.$toast.success(data.data);
            resolve(data);
          })
          .catch(this.$errorHandler)
          .finally(() => {});
      });
    },
  },
  getters: {},
};
