<template>
  <div id="article-data-comp">
    <section class="sc-main">
      <h3 class="mb-4 fsm-5">{{ item.name }}</h3>
      <p class="mb-4 fsm-3">{{ item.description }}</p>
      <a class="mos-btn" :href="$whatsapp" target="_blank">
        Obtener cotización
      </a>
    </section>
  </div>
</template>
<script>
export default {
  props: ["item"],
};
</script>
<style scoped lang="scss">
#article-data-comp {
  width: 50%;
  display: flex;
  align-items: flex-start;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 7%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: 50%;
  }

  .sc-main {
    width: 100%;
    padding-left: 5rem;
    padding-right: 5rem;

    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
    @media (min-width: 768px) {
      padding-top: 3rem;
    }
    @media (min-width: 992px) {
      padding-top: 0;
    }
    h3 {
      font-weight: 600;
      text-transform: uppercase;
    }
    .mos-btn {
      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        padding: 0.75rem;
      }
    }
  }
}
</style>
