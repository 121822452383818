<template>
  <div class="loader">
    <img
      class="ala-izq"
      src="/static/images/misc/fabric-parte-logo-izquierda.svg"
      alt=""
    />
    <img
      class="ala-der"
      src="/static/images/misc/fabric-parte-logo-derecha.svg"
      alt=""
    />
    <img
      class="apoya-izq"
      src="/static/images/misc/fabric-parte-logo-apoya-izq.svg"
      alt=""
    />
    <img
      class="apoya-der"
      src="/static/images/misc/fabric-parte-logo-apoya-der.svg"
      alt=""
    />
    <img
      class="asiento"
      src="/static/images/misc/fabric-parte-logo-asiento.svg"
      alt=""
    />
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.loader {
  width: 120px;
  height: 120px;

  position: relative;
  animation-name: anim-full-loader;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

img {
  position: absolute;
  width: auto;
  animation-direction: normal;
}

.logo-normal {
  height: 150px;
  left: -400px;
}

.ala-izq {
  height: 65px;
  left: 2px;
  z-index: 8888;
  opacity: 0.7;
  top: 11px;
  animation: anim-partes 1s infinite;
  animation-timing-function: ease-in-out;
}

.ala-der {
  height: 70px;
  left: -5%;
  top: 25px;
  z-index: 8887;
  animation: anim-partes 1s infinite;
  animation-timing-function: ease-in-out;
}

.apoya-izq {
  height: 60px;
  left: -45px;
  bottom: 0px;
  z-index: 8887;
  animation: anim-partes 1s infinite;
  animation-timing-function: ease-in-out;
}

.apoya-der {
  height: 60px;
  right: -45px;
  bottom: 0px;
  z-index: 8887;
  animation: anim-partes 1s infinite;
  animation-timing-function: ease-in-out;
}

.asiento {
  height: 40px;
  left: 9%;
  bottom: -25px;
  z-index: 8887;
  animation: anim-partes 1s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes anim-partes {
  0% {
    transform: scale(90%);
  }
  50% {
    transform: scale(105%);
  }
  100% {
    transform: scale(90%);
  }
}
@keyframes anim-full-loader {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-2rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
</style>
