<template>
  <div id="home-aboutus">
    <div class="sc-main scroll-snap anim-wrapper">
      <div class="sc-left">
        <div class="scl-top">
          <div
            class="sclt-left anim-left"
            style="
              transition: all 1s ease;
              -webkit-transition-delay: 1200ms;
              -moz-transition-delay: 1200ms;
              -o-transition-delay: 1200ms;
              transition-delay: 1200ms;
            "
          >
            <div class="span-cont">
              <span class="fsm-6">Nosotros</span>
            </div>
          </div>
          <div
            class="sclt-right"
            :class="[
              isMobile ? 'anim-right' : '',
              isTablet ? 'anim-right' : '',
              !isMobile && !isTablet ? 'anim-down' : '',
            ]"
            style="
              transition: all 1s ease;
              -webkit-transition-delay: 600ms;
              -moz-transition-delay: 600ms;
              -o-transition-delay: 600ms;
              transition-delay: 600ms;
            "
          >
            <div class="scltr-txt-cont">
              <h4 class="fsm-4">Contanos tu idea y la hacemos realidad!</h4>
              <p class="fsm-3">
                A raíz de la pandemia decidimos dejar la venta mayorista para
                abocarnos a cubrir las necesidades del público directo. Nuestros
                años de experiencia nos permiten saber cuáles detalles son los
                que marcan la diferencia, tanto en lo estético como en la
                comodidad y durabilidad del producto
              </p>
            </div>
          </div>
        </div>
        <div
          class="scl-bot"
          :class="[
            isMobile ? 'anim-left' : '',
            isTablet ? 'anim-left' : '',
            !isMobile && !isTablet ? 'anim-up' : '',
          ]"
          style="
            transition: all 1s ease;
            -webkit-transition-delay: 300ms;
            -moz-transition-delay: 300ms;
            -o-transition-delay: 300ms;
            transition-delay: 300ms;
          "
        >
          <img src="/static/images/aboutus/aboutus-img1.webp" alt="" />
        </div>
      </div>
      <div class="sc-right">
        <div
          class="scr-top"
          :class="[
            isMobile ? 'anim-right' : '',
            isTablet ? 'anim-right' : '',
            !isMobile && !isTablet ? 'anim-right' : '',
          ]"
          style="
            transition: all 1s ease;
            -webkit-transition-delay: 900ms;
            -moz-transition-delay: 900ms;
            -o-transition-delay: 900ms;
            transition-delay: 900ms;
          "
        >
          <img src="/static/images/aboutus/aboutus-img2.webp" alt="" />
        </div>
        <div
          class="scr-bot"
          :class="[
            isMobile ? 'anim-left' : '',
            isTablet ? 'anim-left' : '',
            !isMobile && !isTablet ? 'anim-up' : '',
          ]"
          style="
            transition: all 1s ease;
            -webkit-transition-delay: 1200ms;
            -moz-transition-delay: 1200ms;
            -o-transition-delay: 1200ms;
            transition-delay: 1200ms;
          "
        >
          <div class="scrb-txt-cont">
            <p class="fsm-3">
              No pagues de más y obtené mobiliario de primera línea al mejor
              precio directo de fábrica. Te damos la posibilidad de elegir
              absolutamente todos los componentes que prefieras para recibir un
              sillón único que traerá mucho disfrute y le dará vida a tu espacio
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mobileChecker from "@/mixins/mobileChecker";

export default {
  mixins: [mobileChecker],
};
</script>
<style scoped lang="scss">
#home-aboutus {
  width: 100%;
  max-width: 2200px;
  margin: auto;
  max-height: 1500px;

  .sc-main {
    height: calc(100vh - var(--header-height));
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    scroll-snap-align: start;

    @media (max-width: 767px) {
      flex-direction: column;
      height: unset;
    }
    @media (min-width: 768px) {
      height: unset;
      flex-direction: column;
    }
    @media (min-width: 992px) {
      height: calc(100vh - var(--header-height));
      flex-direction: row;
    }

    .sc-left {
      width: 65%;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media (max-width: 767px) {
        width: 100%;
      }
      @media (min-width: 768px) {
        width: 100%;
      }
      @media (min-width: 992px) {
        width: 65%;
        flex-direction: column-reverse;
      }

      .scl-top {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 40%;

        @media (max-width: 767px) {
          height: 22rem;
        }
        @media (min-width: 768px) {
          height: 23rem;
        }
        @media (min-width: 992px) {
          height: 40%;
        }

        .sclt-left {
          height: 100%;
          width: 30%;
          background-color: $color-yellow;
          position: relative;
          padding-left: 20%;

          @media (max-width: 767px) {
            width: 20%;
          }
          @media (min-width: 768px) {
            width: 20%;
          }
          @media (min-width: 992px) {
            width: 30%;
          }

          .span-cont {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            width: calc(16.66667% + 30px);
            height: 100%;
            background-color: var(--background);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 1rem;

            @media (max-width: 767px) {
              padding-right: 1rem;
            }

            span {
              font-weight: 600;
              letter-spacing: -2px;
              -o-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
              transform: rotate(-90deg);
              white-space: nowrap;
              color: $color-beige;
              // -ms-writing-mode: sideways-lr;
              // writing-mode: sideways-lr;
              position: relative;
              height: -webkit-fit-content;
              height: -moz-fit-content;
              height: fit-content;
            }
          }
        }

        .sclt-right {
          height: 100%;
          width: 70%;
          background-color: $color-beige;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 767px) {
            height: 100%;
            width: 80%;
          }
          @media (min-width: 768px) {
            height: 100%;
            width: 80%;
          }
          @media (min-width: 992px) {
            height: 100%;
            width: 70%;
          }

          .scltr-txt-cont {
            width: 80%;
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            @media (max-width: 767px) {
              padding: 2rem;
              width: fit-content;
              height: fit-content;
            }
            @media (min-width: 768px) {
              padding: 5rem;
              width: fit-content;
              height: fit-content;
            }
            @media (min-width: 992px) {
              padding: 0;
              width: 80%;
              height: 80%;
            }

            & > * {
              text-align: left;
            }

            h4 {
              margin-bottom: 1.5rem;
            }

            p {
            }
          }
        }
      }

      .scl-bot {
        height: 60%;
        width: 100%;

        @media (max-width: 767px) {
          height: 15rem;
        }
        @media (min-width: 768px) {
          height: 25rem;
        }
        @media (min-width: 992px) {
          height: 60%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    .sc-right {
      width: 35%;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media (max-width: 767px) {
        width: 100%;
        flex-direction: column-reverse;
      }
      @media (min-width: 768px) {
        width: 100%;
        flex-direction: row-reverse;
      }
      @media (min-width: 992px) {
        width: 35%;
        flex-direction: column-reverse;
      }

      .scr-top {
        height: 65%;
        width: 100%;

        @media (max-width: 767px) {
          height: 22rem;
        }
        @media (min-width: 768px) {
          height: 25rem;
          width: 55%;
        }
        @media (min-width: 992px) {
          height: 65%;
          width: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: bottom;
        }
      }
      .scr-bot {
        width: 100%;
        height: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-primary;

        @media (max-width: 767px) {
          height: 14rem;
        }
        @media (min-width: 768px) {
          height: 25rem;
          width: 45%;
        }
        @media (min-width: 992px) {
          height: 35%;
          width: 100%;
        }

        .scrb-txt-cont {
          width: 75%;
          height: fit-content;
        }
      }
    }
  }
}
</style>
