import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import InstagramFeed from "vue3-instagram-feed";
import "vue3-instagram-feed/dist/style.css";
import toast from "./utils/toast";

const app = createApp(App);
app.config.globalProperties.$instagram = "fabrictienda";
app.config.globalProperties.$whatsapp = `https://wa.me/${process.env.VUE_APP_WHATSAPP_NUMBER}`;
app.use(store).use(toast).use(router).use(InstagramFeed).mount("#app");
